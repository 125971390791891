<template>
  <div class="content-wrapper container">
    <div
      class="close"
      @click="closeCarousel"
    >
      X
    </div>
    <div
      id="gallery-carousel"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div
          v-for="(img, index) in images_src"
          :key="index"
          :data-slide-to="index"
          class="item carousel-item"
          :class="index == 0 ? 'active' : ''"
        >
          <img :src="img">
        </div>
      </div>

      <ol class="carousel-indicators list-inline">
        <li
          v-for="(img, index) in images_src"
          :key="index"
          :data-slide-to="index"
          :class="index == 0 ? 'active' : ''"
          class="list-inline-item"
          @click="showSlide(index)"
        >
          <a
            :id="'carousel-selector-' + index"
            :class="index == 0 ? 'selected' : ''"
            data-target="#gallery-carousel"
          >
            <img
              :src="img"
              class="img-fluid"
            >
          </a>
        </li>
      </ol>
    </div>

    <a
      href="#gallery-carousel"
      class="carousel-control left"
      data-slide="prev"
      role="button"
    >
      <i class="fa fa-chevron-left" />
      <span class="sr-only">Previous</span>
    </a>

    <a
      href="#gallery-carousel"
      class="carousel-control right"
      data-slide="next"
      role="button"
    >
      <i class="fa fa-chevron-right" />
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      images_src: {
        type: Array,
      },
    }
  },

  mounted() {
    let imgs = document.querySelectorAll('.mobile-gallery .item img')
    let imgs_src = []
    imgs.forEach(function (img) {
      imgs_src.push(img.src)
    })
    this.images_src = imgs_src
  },

  methods: {
    closeCarousel() {
      document.querySelector('.gallery-content').classList.add('hide')

      document.querySelector('body').classList.remove('overflow')
    },

    showSlide(index) {
      $('#gallery-carousel').carousel(index)
    },
  },
}
</script>
