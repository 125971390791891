<template>
  <div class="gallery">
    <img
      v-for="(img, index) in images_src"
      :key="index"
      :src="img"
      :class="index == 0 ? 'image_grid' : ''"
      :data-slide-to="index"
      class="gallery-image"
    >
    <button
      type="button"
      class="show-all-photos"
    >
      Show All Photos
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      images_src: {
        type: Array,
      },
    }
  },
  mounted() {
    let images = document.querySelectorAll('.mobile-gallery .item img')
    
    let imgs_src = []
    for(let i = 0; i < 5; i++) {
      imgs_src.push(images[i].src)
    }
    this.images_src = imgs_src
  },
}
</script>