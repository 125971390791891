let totalSlides = null

const showHideSlides = (index) => {
  document.getElementsByClassName('gallery-content')[0].classList.remove('hide')
  document.querySelector('body').classList.add('overflow')

  $('#gallery-carousel').carousel(index)
}

$(document)
  .on('click', '.show-all-photos', function () {
    showHideSlides(0)
  })

  .on('click', '.gallery .gallery-image', function (e) {
    let index = e.currentTarget.getAttribute('data-slide-to')
    showHideSlides(parseInt(index))
  })

  .on('click', '.gallery-content .carousel-control', function () {
    let index = $(this).data('slide')
    $('#gallery-carousel').carousel(index)
  })

  .on('slid.bs.carousel', '#mobile-gallery-carousel', function () {
    let index = $(this).find('.active').index()
    document.querySelector('#mobile-gallery-carousel .current_slide').innerHTML = index + 1
  })

document.querySelectorAll('#mobile-gallery-carousel .item').forEach((elem, index) => {
  elem.setAttribute('data-slide-to', index)

  var touchStartX = null
  elem.addEventListener('touchstart', (e) => {
    if (e.touches.length == 1)
      touchStartX = e.touches[0].pageX
  })

  elem.addEventListener('touchmove', (e) => {
    if (touchStartX != null) {
      let touchCurrentX = e.changedTouches[0].pageX
      if ((touchCurrentX - touchStartX) > 60) {
        touchStartX = null
        $('#mobile-gallery-carousel').carousel('prev')
      } else if ((touchStartX - touchCurrentX) > 60) {
        touchStartX = null
        $('#mobile-gallery-carousel').carousel('next')
      }
    }
  })

  elem.addEventListener('touchend', () => {
    touchStartX = null
  })

  elem.addEventListener('click', () => {
    showHideSlides(index)
  })
})


document.addEventListener('turbo:load', () => {
  totalSlides = document.querySelectorAll('#mobile-gallery-carousel .item').length
  const documentTotalSlides = document.querySelector('#mobile-gallery-carousel .total_slides')
  const currentSlide = document.querySelector('#mobile-gallery-carousel .current_slide')
  if(documentTotalSlides){
    documentTotalSlides.innerHTML = totalSlides
  }
  if (currentSlide){
    currentSlide.innerHTML = 1
  }
})
